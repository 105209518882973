<template>
<div class="container-fluid mt-5 pt-5">
    <BasePageBreadCrumb :pages="pages" :title="title" :menu="menu"/>

     <div class="container-fluid qsuite-c-fluid-0">
        <div class="container-fluid qsuite-c-fluid-0">
            <div class="col-12">
                <div class="card card-bg-trans">
                    <div class="card-body pt-4 px-0">
                        <form action="" class="px-2 needs-validation" novalidate="">
                            <div class="form-body">
                                <div class="row">
                                    <div class="col-12 text-center">
                                        <p class="m-0">Para dicas sobre integração, fale com o nosso suporte. </p>
                                    </div>
                                    <div class="col-12 pb-2">
                                        <label>NOME DO INTEGRADOR</label>
                                        <div class="input-group">
                                            <div class="input-group-prepend">
                                                <div class="input-group-text text-uppercase" id="btnGroupAddon2">{{user.company.name}}</div>
                                            </div>
                                            <input type="text" :disabled="id" class="form-control" placeholder="" v-model="credentials.name" aria-label="Input group example" aria-describedby="btnGroupAddon2">
                                        </div>
                                    </div>
                                    <div class="col-12 pb-2">
                                        <label>Api base</label>
                                        <div class="input-group">
                                            <div class="input-group-prepend">
                                                <div class="input-group-text text-uppercase" id="btnGroupAddon2">{{user.company.name}}</div>
                                            </div>
                                            <input type="text" class="form-control" placeholder="ex: https://minha.api.dns.example"  v-model="credentials.api_base" aria-label="Input group example" aria-describedby="btnGroupAddon2">
                                        </div>
                                    </div>
                                    <div class="col-12 pb-2">
                                        <label>Api user</label>
                                        <div class="input-group">
                                            <div class="input-group-prepend">
                                                <div class="input-group-text text-uppercase" id="btnGroupAddon2">{{user.company.name}}</div>
                                            </div>
                                            <input type="text" class="form-control" placeholder="" v-model="credentials.api_user"  aria-label="Input group example" aria-describedby="btnGroupAddon2">
                                        </div>
                                    </div>
                                    <div class="col-12 pb-2">
                                        <label>Api password</label>
                                        <div class="input-group">
                                            <div class="input-group-prepend">
                                                <div class="input-group-text text-uppercase" id="btnGroupAddon2">{{user.company.name}}</div>
                                            </div>
                                            <input type="text" class="form-control" placeholder="" v-model="credentials.api_password"  aria-label="Input group example" aria-describedby="btnGroupAddon2">
                                        </div>
                                    </div>
                                    <div class="col-12 pb-2">
                                        <label>Token</label>
                                        <div class="input-group">
                                            <div class="input-group-prepend">
                                                <div class="input-group-text text-uppercase" id="btnGroupAddon2">{{user.company.name}}</div>
                                            </div>
                                            <input type="text" class="form-control" placeholder="" v-model="credentials.api_token"  aria-label="Input group example" aria-describedby="btnGroupAddon2">
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div class="form-actions">
                                <div class="text-right">
                                    <button type="button" @click="save()" class="btn btn-info">Salvar</button>
                                </div>
                            </div>
                            <!-- <hr>
                            <div class="form-actions">
                                <div class="text-center">
                                    <button type="submit" class="btn btn-info">Sincronizar dados com Qsuíte</button>
                                    <button type="submit" class="btn btn-dark">Atualização automática</button>
                                </div>
                            </div>
                            <div class="col-12 text-center">
                                <p class="m-0">Última atualizacão em 20/02/2021</p>
                            </div>
                            <hr>
                            <div class="form-body">
                                <div class="row">
                                    <div class="col-12 col-md-4 col-lg-4">
                                        <div class="form-group">
                                            <label>EMPREENDIMENTOS</label>
                                            <select class="custom-select mr-sm-2" id="inlineFormCustomSelect">
                                                <option selected="">Selecione</option>
                                                <option value="2">HUB_</option>
                                                <option value="1">Legacy</option>
                                            </select>
                                        </div>
                                    </div>
                                    <div class="col-12 pb-2">
                                        <label>Dados </label>
                                        <div class="input-group">
                                            <div class="input-group-prepend">
                                                <div class="input-group-text" id="btnGroupAddon2"><i class="fas fa-search"></i></div>
                                            </div>
                                            <input type="text" class="form-control" placeholder="Procurar" aria-label="Input group example" aria-describedby="btnGroupAddon2">
                                        </div>
                                    </div>
                                    <div class="col-12">
                                        <div data-spy="scroll" data-offset="0" class="position-relative mt-2 scroll-1" style="height: 500px;">
                                            <div class="col-12">
                                                <div class="row">
                                                    <div class="col-12 col-md-6 col-lg-4 py-2">
                                                        <div class="custom-control custom-checkbox">
                                                            <input type="checkbox" class="custom-control-input" id="1">
                                                            <label class="custom-control-label check-label" for="1">
                                                                Rafael dos santos dias<br>
                                                                HUB_ | APTO(S) 305, 2001 e 2501<br>
                                                                001.09*.***-01 | (62) 99889-8934 | rafael@rafaeldias.com.br
                                                            </label>
                                                        </div>
                                                    </div>
                                                    <div class="col-12 col-md-6 col-lg-4 py-2">
                                                        <div class="custom-control custom-checkbox">
                                                            <input type="checkbox" class="custom-control-input" id="1">
                                                            <label class="custom-control-label check-label" for="1">
                                                                Rafael dos santos dias<br>
                                                                HUB_ | APTO(S) 305, 2001 e 2501<br>
                                                                001.09*.***-01 | (62) 99889-8934 | rafael@rafaeldias.com.br
                                                            </label>
                                                        </div>
                                                    </div>
                                                    <div class="col-12 col-md-6 col-lg-4 py-2">
                                                        <div class="custom-control custom-checkbox">
                                                            <input type="checkbox" class="custom-control-input" id="1">
                                                            <label class="custom-control-label check-label" for="1">
                                                                Rafael dos santos dias<br>
                                                                HUB_ | APTO(S) 305, 2001 e 2501<br>
                                                                001.09*.***-01 | (62) 99889-8934 | rafael@rafaeldias.com.br
                                                            </label>
                                                        </div>
                                                    </div>
                                                    <div class="col-12 col-md-6 col-lg-4 py-2">
                                                        <div class="custom-control custom-checkbox">
                                                            <input type="checkbox" class="custom-control-input" id="1">
                                                            <label class="custom-control-label check-label" for="1">
                                                                Rafael dos santos dias<br>
                                                                HUB_ | APTO(S) 305, 2001 e 2501<br>
                                                                001.09*.***-01 | (62) 99889-8934 | rafael@rafaeldias.com.br
                                                            </label>
                                                        </div>
                                                    </div>
                                                    <div class="col-12 col-md-6 col-lg-4 py-2">
                                                        <div class="custom-control custom-checkbox">
                                                            <input type="checkbox" class="custom-control-input" id="1">
                                                            <label class="custom-control-label check-label" for="1">
                                                                Rafael dos santos dias<br>
                                                                HUB_ | APTO(S) 305, 2001 e 2501<br>
                                                                001.09*.***-01 | (62) 99889-8934 | rafael@rafaeldias.com.br
                                                            </label>
                                                        </div>
                                                    </div>
                                                    <div class="col-12 col-md-6 col-lg-4 py-2">
                                                        <div class="custom-control custom-checkbox">
                                                            <input type="checkbox" class="custom-control-input" id="1">
                                                            <label class="custom-control-label check-label" for="1">
                                                                Rafael dos santos dias<br>
                                                                HUB_ | APTO(S) 305, 2001 e 2501<br>
                                                                001.09*.***-01 | (62) 99889-8934 | rafael@rafaeldias.com.br
                                                            </label>
                                                        </div>
                                                    </div>
                                                
                                                
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <hr>
                            <div class="col-12 text-center">
                                <p class="m-0"><b>102</b> cadastros selecionados | <b>12</b> novas atualizações</p>
                            </div>
                            <hr>
                            <div class="form-actions">
                                <div class="text-left">
                                    <button type="reset" class="btn btn-dark">Marcar todos</button>
                                    <button type="submit" class="btn btn-info">Atualizar</button>
                                </div>
                            </div> -->
                        </form>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>

</template>
<script>

import integrationService from "@/services/resources/IntegrationService";
import { dodgerblue } from "color-name";
const service = integrationService.build();

export default {
   data(){
       return{
            pages:[
                {
                    name: 'dashboard',
                    to: 'Dashboard'
                },
                {
                    name: 'integrações',
                    to: 'Integration'
                },
                {
                    name: 'caps',
                    to: 'Integration'
                }
            ],
            integrations: [],
            credentials: {
                name: null,
                api_base: null,
                api_user: null,
                api_password: null,
                api_token: null
            },
            title: "dados de integração (API)",
            name: ''
       }
   },
   computed:{
       user() {
            return this.$store.getters.getProfile;
        }
   },
   methods:{
      
    fetchIntegration(){

        if(!this.id){
            return;
        }

        let data = {
            id: this.id
        };

        service
        .read(data)
        .then(resp => {
            this.credentials = resp;
        
            this.title = resp.name + " - dados de integração (API)"
        })
        .catch(err => {
            console.log(err);
        });
    },
    save() {
        
        if(this.id){

           this.credentials.id = this.id; 
           service
            .update(this.credentials)
            .then(resp => {

                  this.$bvToast.toast('Alterações realizadas com sucesso', {
                    title: 'Kit',
                    autoHideDelay: 5000,
                    type: 'success'
                })
                
            })
            .catch(err => {
                this.$bvToast.toast(err.message, {
                    title: 'Kit',
                    autoHideDelay: 5000,
                    type: 'error'
                })
            });

        } else {

            service
            .create(this.credentials)
            .then(resp => {
                 this.$bvToast.toast('Integração cadastrada com sucesso', {
                    title: 'Kit',
                    autoHideDelay: 5000,
                    type: 'success'
                })
                
            })
            .catch(err => {
                this.$bvToast.toast(err.message, {
                    title: 'Kit',
                    autoHideDelay: 5000,
                    type: 'error'
                })
            });

        }
    }

   },
    mounted() {
    
    this.id = this.$route.params.id;
    this.fetchIntegration()

  },
}
</script>